.d-flex {
  display: flex;
}

.d-none{
  display: none;
}

.align-items-center {
  align-items: center !important;
}

.align-items-start {
  align-items: start !important;
}

.align-items-end {
  align-items: end !important;
}


.justify-content-center{
  justify-content: center !important;
}

.justify-content-end{
  justify-content: end;
}

.justify-content-between{
  justify-content: space-between;
}

.gap-20{
  gap: 20px;
}
