// global

* {
  outline: none;
  box-sizing: border-box;
}

html, body {
  height: 100%;
}

body {
  font-family: $body-font;
  margin: 0;
  overflow-x: hidden;
  color: $body-text-color;
  font-weight: 300;
  background: $body-color;
}

html {
  position: relative;
  min-height: 100%;
  background: $white;
}

.w-100 {
  width: 100%;
}

.flex-column {
  flex-direction: column;
}

.h-100{
  height: 100%;
}

.text-primary {
  color: $primary-color !important;
}

.text-black {
  color: #000000;
}

.text-secondary {
  color: $secondary-color !important;
}

.text-tertiary {
  color: $tertiary-light-color;
}

.page-wrapper {
  width: 100%;
  //min-height: 100vh;
  padding: 150px 0 0 0;

  &-vert {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &.bg-mountain {
    background: url('/assets/images/bg-mountain.jpg') no-repeat top center;
    background-size: cover;
    background-attachment: fixed;
  }

  &.bg-waves {
    background: url('/assets/images/bg-waves.jpg') no-repeat top center;
    background-size: cover;
    background-attachment: fixed;
  }
}

.header {
  top: 0;
  left: 0;
  width: 100%;
  z-index: 200;
  display: flex;
  position: absolute;
  padding: 20px 40px;
  flex-direction: column;

  &-white {
    .header-bar-line {
      background-color: $white;
    }
  }

  &-logo {
    width: 98px;
  }

  &-bar {
    display: flex;
    margin: 20px 0 0 0;
    align-items: start;
    justify-content: space-between;

    &-line {
      height: 1px;
      flex-grow: 1;
      margin: 0 0 0 0;
      background-color: $primary-color;

      &.small {
        max-width: 10%;
      }
    }

    &-logo {
      top: -35px;
      margin: 0 20px 0 40px;
      position: relative;
    }
  }

  &-user {
    gap: 20px;
    top: 7px;
    right: 30px;
    padding: 0 10px 0 0;
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: space-between;

    &-thumb {
      width: 50px;
      height: 50px;
      object-fit: cover;
      border-radius: 50px;
      border: 4px solid $tertiary-light-color;
    }

    &-label {
      color: $white;
      font-size: 16px;
    }

    &-options {
      padding: 5px 5px;
      border-left: 1px solid $white;

      &-item {
        color: $white;
        margin: 0 15px;
        font-weight: bold;
        text-decoration: none;
        transition: color 0.3s ease;

        &:hover {
          color: $tertiary-light-color;
        }
      }
    }
  }
}

.footer {
  width: 98%;
  margin: 0 auto;
  padding: 20px 0;
  color: $primary-color;

  @include lt-sm() {
    width: 90%;
  }

  p {
    font-size: 12px;
    font-weight: 400;
  }
}

.page-content {
  width: 100%;
  z-index: 10;
  position: relative;
  margin: -70px auto 0;
}

.btn-default {
  gap: 20px;
  display: flex;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 16px;
  min-width: 278px;
  min-height: 40px;
  font-weight: bold;
  font-family: $heading-font;
  align-items: center;
  border-radius: 35px;
  color: $primary-color;
  justify-content: center;
  background-color: transparent;
  border: 2px solid $primary-color;
  transition: background 0.3s ease;

  &.selected{
    background-color: $primary-color;
    color: $white;
  }

  &-white {
    color: $white;
    border-color: $white;

    &:hover {
      color: $primary-color !important;
      background-color: $white !important;
    }
  }

  &-primary {
    color: $white;
    border-color: $primary-color;
    background-color: $primary-color;

    .btn-default-icon {
      color: $white !important;
    }

    &:hover {
      color: $white !important;
      border-color: $primary-color !important;
      background-color: $primary-color !important;
    }
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;

    &:hover {
      background-color: transparent !important;

      .btn-default-label,
      .btn-default-icon {
        color: $primary-color !important;
      }
    }
  }

  @include lt-sm() {
    font-size: 14px;
    min-width: 140px;
  }

  &.no-border {
    border: none;
  }

  &:hover {
    color: $white;
    background-color: $primary-color;

    .btn-default-label,
    .btn-default-icon {
      color: $white;
    }
  }

  &-label {
    flex-grow: 1;
    text-align: center;
    transition: color 0.3s ease;
  }

  &-icon {
    color: $primary-color;
    transition: color 0.3s ease;
  }
}

.inside {

  &-title {
    font-size: 36px;
    font-weight: bold;
    margin: 0 0 30px 0;
    color: $tertiary-color;
  }

  &-header {
    display: flex;
    margin: 0 0 40px;
    justify-content: space-between;

    &-title {
      margin: 0 15px 0 0;
      font-size: 22px;
      font-weight: bold;
      color: $primary-color;
    }

    &-steps {
      flex-grow: 1;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: start;

      @include lt-md() {
        gap: 2px;
      }

      &-item {
        width: 27px;
        height: 27px;
        display: flex;
        margin: 0 5px;
        opacity: 0.4;
        font-size: 13px;
        font-weight: bold;
        align-items: center;
        border-radius: 27px;
        color: $tertiary-color;
        justify-content: center;
        border: 2px solid $tertiary-color;

        &.active {
          opacity: 1;
          color: $theme-color;
          border-color: $theme-color;
        }

        &.current {
          opacity: 1;
          color: $tertiary-color;
          border-color: $tertiary-color;
        }
      }
    }
  }

  &-content {
    width: 60%;
    display: flex;
    min-height: 74vh;
    padding: 0 0 0 75px;
    align-items: stretch;
    flex-direction: column;
    justify-content: space-between;

    @include lt-sm() {
      width: 90%;
      padding: 0 0 0 0px;
    }

    &.full {
      width: 100%;
      padding: 0 75px;

      @include lt-sm() {
        padding: 0 25px;
      }
    }
  }

  &-question {
    font-size: 36px;
    font-weight: bold;
    margin: 0 0 40px 0;
    color: $tertiary-color;

    @include lt-sm() {
      font-size: 26px;
    }

    &-light {
      font-size: 25px;
      color: $primary-color;
    }
  }

  &-options {
    display: flex;
    align-items: start;
    flex-direction: column;

    &.full {
      min-width: 100%;
    }

    &-item {
      display: flex;
      min-width: 100%;
      font-size: 25px;
      margin: 0 0 40px 0;
      align-items: center;
      padding: 0 0px 10px;
      color: $primary-color;
      justify-content: space-between;
      border-bottom: 2px solid #E6E6E6;

      @include lt-sm() {
        align-items: start;
        flex-direction: column;
      }

      .mat-form-field-wrapper {
        margin: 0 !important;
        padding: 0 !important;
      }

      strong {
        margin: 0 15px 0 0;
        display: inline-block;
      }

      &-text {
        width: 70%;
        flex-grow: 1;
        font-size: 16px;
        font-weight: bold;
        margin: 0 20px 0 0;

        @include lt-sm() {
          margin: 0 0 15px 0;
        }

        &.intro {
          font-size: 22px;
          font-weight: 400;
        }
      }

      &-select {
        max-width: 280px;
        color: $tertiary-color;
        border-color: $tertiary-color;
      }
    }
  }

  &-buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

}

.result {
  &-profile {
    display: flex;
    max-width: 50%;
    align-items: center;
    justify-content: space-between;

    @include lt-sm() {
      max-width: 100%;
      align-items: start;
      flex-direction: column;
    }

    &-thumb {
      width: 150px;
      height: 150px;
      border-radius: 150px;
    }

    &-info {
      flex-grow: 1;
      margin: 0 0 0 30px;
      color: $primary-color;

      @include lt-sm() {
        margin: 30px 0px 0;
      }

      &-title {
        font-size: 24px;
        font-weight: bold;
        margin: 0 0 15px 0;
      }

      &-description {
        font-size: 16px;
        font-weight: 400;
        margin: 0 15px 0 0;
      }
    }
  }

  &-videos {
    width: 100%;
    display: flex;
    margin: 50px 0 0 0;
    align-items: start;
    justify-content: space-between;

    @include lt-sm() {
      flex-direction: column;
    }

    &-item {
      width: 18%;
      flex-grow: 1;
      cursor: pointer;
      margin: 0 1% 0 0;
      overflow: hidden;
      position: relative;
      border-radius: 12px;
      background-color: #000000;

      @include lt-sm() {
        width: 100%;
        margin: 0 0 30px 0;
      }

      &:hover {
        .result-videos-item-play {
          transform: scale(1.1);
        }

        .result-videos-item-thumb {
          opacity: 0.7;
          filter: grayscale(0.1);
        }

        .result-videos-item-legend {
          padding: 27px 0;
        }
      }

      &.active {
        width: 40%;

        @include lt-sm() {
          width: 100%;
        }

        .result-videos-item-thumb {
          opacity: 1;
          height: 400px;
          filter: grayscale(0);
        }

        .result-videos-item-legend {
          bottom: 1px;
          background-color: rgba(1, 0, 60, 0.7);
        }

        .result-videos-item-play {
          top: auto;
          left: 60px;
          bottom: 80px;
        }

        &:hover {
          .result-videos-item-legend {
            padding: 37px 0;
          }
        }
      }

      &-thumb {
        width: 100%;
        opacity: 0.5;
        height: 300px;
        object-fit: cover;
        margin-bottom: -4px;
        border-radius: 12px;
        filter: grayscale(1.0);
        transition: opacity 0.3s ease, filter 0.3s ease;
      }

      &-play {
        top: 50%;
        left: 50%;
        z-index: 10;
        position: absolute;
        margin: -30px 0 0 -16px;
        transition: transform 0.3s ease;
      }

      &-legend {
        left: 0;
        bottom: 0;
        width: 100%;
        color: $white;
        padding: 20px 0;
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        position: absolute;
        text-transform: uppercase;
        border-top: 2px solid $white;
        transition: padding 0.3s ease;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
      }
    }
  }
}

.form-default {
  width: 100%;
  display: flex;
  flex-direction: column;
  // min-height: 60vh;

  .result-profile {
    width: 100%;
    max-width: 100%;
  }
}

.table-light {
  width: 100%;

  td {
    padding: 5px;
  }

  &-icon {
    color: $white;
    width: 20px !important;
    height: 20px !important;
    font-size: 20px !important;

    &.success {
      color: #3ad600;
    }

    &.danger {
      color: #ff0000;
    }
  }
}

.page-square {
  right: 0;
  bottom: 0;
  width: 40%;
  height: 60vh;
  display: flex;
  position: fixed;
  background: url('/assets/images/bg-square.png') no-repeat top left;

  /*&-primary{
    width: 300px;
    height: 200px;
    transform: rotate(45deg);
    background-color: $tertiary-color;
  }*/
}
