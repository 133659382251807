/* You can add global styles to this file, and also import other style files */
@import 'front/variable';
@import 'front/theme';
@import 'front/fonts';

@import 'front/mixins/spacing';
@import 'front/mixins/color';
@import 'front/mixins/font';
@import 'front/mixins/display';
@import 'front/mixins/borders';

@import 'front/component/global';
@import 'front/component/heading';

@import 'front/component/header';
@import 'front/component/sidebar';
@import 'front/component/spinner';
@import 'front/component/card';
@import 'front/component/dashboard';

@import 'front/responsive';


/* MODAL */
.cdk-overlay-backdrop {
  &.cdk-overlay-backdrop-showing {
    background: rgba(0, 0, 0, .85) !important;
    opacity: 1 !important;
  }
}


// SELECT RADIUS
.custom-field-radius.mat-form-field-appearance-outline .mat-form-field-outline-start,
.custom-field-radius.mat-form-field-appearance-outline .mat-form-field-outline-end,
.custom-field-radius.mat-form-field-appearance-outline .mat-form-field-outline-gap {
  border-color: $tertiary-color !important;
}

.custom-field-radius.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-outline-gap {
  border-top-color: rgba(0, 0, 0, 0) !important;
}

.custom-field-radius.mat-form-field-appearance-outline .mat-form-field-flex {
  line-height: 17px;
  padding: 0 0.75em 0 2.25em !important
}

.mat-form-field-outline-start {
  border-radius: 28px 0 0 28px !important;
  min-width: 28px !important;
}

.mat-form-field-outline-end {
  border-radius: 0 28px 28px 0 !important;
}

.mat-form-field-label {
  color: $tertiary-color;
}

.mat-stroked-button:not(.mat-button-disabled) {
  border-color: #474949;
}

.custom-field-radius.mat-form-field-appearance-outline .mat-form-field-outline {
  color: $tertiary-color !important;

  label {
    color: $tertiary-color !important;
  }
}

.custom-field-radius.mat-form-field-appearance-outline .mat-form-field-outline-start,
.custom-field-radius.mat-form-field-appearance-outline .mat-form-field-outline-end,
.custom-field-radius.mat-form-field-appearance-outline .mat-form-field-outline-gap {
  border-width: 2px !important;
}

// INPUT
.mat-form-field-appearance-legacy .mat-form-field-label {
  color: $tertiary-color !important;
}

.mat-form-field-appearance-legacy .mat-form-field-underline,
.mat-form-field-ripple {
  background-color: $tertiary-color !important;
}

.datepicker-field {
  &.mat-form-field-appearance-standard {
    .mat-form-field-flex {
      padding-top: 0;
    }

    .mat-form-field-infix {
      padding: 0 0;
    }
  }

  .mat-form-field-wrapper {
    // height: 100px;
    .mat-datepicker-toggle {
      float: right;
      margin: -6px 0 -6px 0;
      display: inline-block;
    }

    .mat-datepicker-input {
      top: 7px;
      left: 3px;
      position: absolute;
    }
  }
}

.video-box {
  width: 100%;
  margin: 0 auto;
  cursor: pointer;
  max-width: 871px;

  video {
    border-radius: 28px;
  }

  &-thumb {
    width: 100%;
  }

  iframe {
    @include lt-sm() {
      height: 300px !important;
    }
  }

}

#modalVideo {
  padding: 0 !important;
  background-color: #000000;
}


// PAGE
.page {
  width: 100%;
  display: flex;
  min-height: 100vh;
  padding: 35px 50px;
  align-items: stretch;
  justify-content: space-between;

  &.bg-vert {
    &:after {
      top: 0;
      left: 0;
      width: 40%;
      content: ' ';
      display: block;
      min-width: 495px;
      min-height: 100%;
      position: fixed;
      background: url("/assets/images/home/bg-home.jpg") no-repeat top left;
      background-size: cover;
    }

    /*.page-inside{
      width: 30%;
      font-size: 20px;
      min-width: 380px;
    }*/
  }

  &.bg-horiz {

    &:after {
      top: 0;
      left: 0;
      width: 100%;
      content: ' ';
      display: block;
      height: 323px;
      position: absolute;
      background: url("/assets/images/home/bg-home.jpg") no-repeat top left;
      background-size: cover;
    }

    .page-inside {
      // width: 30%;
      min-width: 380px;
    }
  }

  &.bg-home {
    background: url("/assets/images/home/bg-home.jpg") no-repeat top center;
    background-size: cover;
  }

  &.bg-login {
    background: url("/assets/images/iniciar-lider.jpg") no-repeat top center;
    background-size: cover;
  }

  &.bg-project {
    /*background-image: url("/assets/images/projects/bg-project.png");
    background-size: contain !important;*/
    &:after {
      background-image: url("/assets/images/projects/bg-project.png") !important;
    }
  }

  &-inside {
    width: 100%;
    flex-grow: 1;

    &-row {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      gap: 27px;
    }

    &-col {
      width: 40%;

      &.custom-video {
        @media (min-width: 1400px) {
          flex-grow: 1;
        }
        width: 678px;
        height: 400px;
      }

      &-badge-time {
        width: 180px;
        margin: 12px 0 0;
        height: 59px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-shrink: 0;
        border-radius: 28px;
        border: 2px solid $primary-color;
        background: #FFF;

        span {
          color: #000;
          font-size: 32px;
          font-style: normal;
          font-weight: 700;
        }
      }

      &-card-projects {
        gap: 40px;
        display: flex;
        cursor: pointer;
        padding: 0 60px;
        min-height: 120px;
        align-items: center;
        border-radius: 28px;
        background: #D9D9D9;
        transition: background 0.3s ease;

        &.active {
          background: $primary-color;

          .page-inside-col-card-projects-numb,
          .page-inside-col-card-projects-desc {
            color: $white;
          }
        }

        &:hover {
          background: #bdbdbd;
        }

        &-numb {
          color: #000;
          font-size: 45px;
          font-style: normal;
          font-weight: 700;
        }

        &-desc {
          color: #000;
          font-size: 27px;
          font-style: normal;
          font-weight: 700;
          line-height: 28px;

          &.flex {
            flex: 2;
          }
        }
      }

      &.custom-padding {
        padding: 40px 0 0;
      }

      .text-secondary {
        color: #000;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: var(--Label-Large-Tracking, 0.1px);
      }

      &-video {
        display: flex;
        flex-direction: column;
        gap: 25px;

        &-desc {
          color: #000;
          font-size: 20px;
          font-style: normal;
          line-height: 20px;
          letter-spacing: 0.1px;

          p {
            margin: 0;
          }

          &-label {
            color: #000;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.1px;
          }

          &-big {
            color: #000;
            font-size: 48px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
            letter-spacing: 0.1px;
            margin: 30px 0 !important;
          }
        }
      }

      &-description {
        color: #000;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.1px;
        margin: 27px 0 0;
      }

      &-container-tech {
        display: flex;
        flex-direction: column;
        gap: 15px;
        max-height: 590px;
        overflow: auto;

        &::-webkit-scrollbar {
          display: none;
        }

        &-card {
          display: flex;
          padding: 23px 20px;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          border-radius: 16px;
          border: 1px solid #D9D9D9;
          background: #FFF;

          &-header {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            &-title {
              color: #000;
              font-size: 18px;
              font-style: normal;
              font-weight: 700;
              text-transform: uppercase;
            }

            &-toks {
              color: $primary-color;
              text-align: right;
              font-size: 17px;
              font-style: normal;
              font-weight: 700;
            }
          }

          &-body {
            &-description {
              color: #000;
              font-size: 15px;
              font-style: normal;
              font-weight: 400;
              line-height: 29px;
            }
          }

          &-footer {
            width: 100%;
            display: flex;
            justify-content: start;
          }
        }
      }

      &-tech-wallet {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &-header {
          margin: 0 0 23px;
          display: flex;
          flex-direction: column;

          &-title {
            color: #000;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
          }

          &-description {
            color: $primary-color;
            font-size: 32px;
            font-style: normal;
            font-weight: 700;
          }
        }

        &-body {
          border-radius: 12px;
          background: #D9D9D9;
          padding: 15px;
          margin: 0 0 23px;

          &-title {
            color: #000;
            font-size: 15px;
            font-style: normal;
            font-weight: 700;
          }

          &-description {
            color: #000;
            font-size: 15px;
            font-style: normal;
            font-weight: 700;
          }
        }

        &-tools {
          display: flex;
          flex-direction: column;
          gap: 15px;
          cursor: pointer;

          &-title {
            color: #000;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            margin: 0 0 10px;
          }

          &-card {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 15px;
            border-radius: 12px;
            border: 1px solid #D9D9D9;
            color: $primary-color;
            font-size: 15px;
            font-style: normal;
            font-weight: 700;

            &-title-selected {
              font-size: 13px !important;
            }
          }
        }
      }
    }

    &-header {
      display: flex;
      flex-direction: column;
      justify-content: end;

      .text-white {
        font-size: 29px;
      }

      &-brand {
        width: 273px;
        height: 91px;
      }
    }

    &-content {
      width: 55%;
    }
  }

  &-footer {
    gap: 25px;
    width: 100%;
    display: flex;
    align-items: end;
  }

  &-border {
    width: 100%;
    z-index: 10;
    flex-grow: 1;
    display: flex;
    min-height: 600px;
    padding: 45px 80px;
    position: relative;
    align-items: start;
    border-radius: 38px;
    border: 2px solid $white;
    flex-direction: column;
    justify-content: space-between;

    &-top {
      padding: 115px 80px 45px;
    }

    &.red {
      border-color: $primary-color;

      .page-border-badge {
        border: 2px solid $primary-color !important;

        //&.top {
        //  border-color: $white !important;
        //
        //
        //  &.border-primary{
        //    border-color: $primary-color !important;
        //  }
        //}
      }
    }

    &-badge {
      gap: 15px;
      // top: 50%;
      display: flex;
      color: $primary-color;
      font-size: 22px;
      font-weight: bold;
      padding: 25px 10px;
      position: absolute;
      letter-spacing: 2px;
      border-radius: 38px;
      border: 2px solid $white;
      background-color: $white;


      &.left {
        top: 35%;
        left: -25px;
      }

      &.right {
        top: 27%;
        right: -25px;
      }

      &.top {
        left: 50%;
        top: -22px;
        width: 265px;
        display: flex;
        color: #D9D9D9;
        font-size: 18px;
        padding: 5px 30px;
        margin-left: -140px;

        .step-item {
          cursor: default;
          margin: 0 8px;
        }

        .active {
          color: $primary-color;
        }

        .past-step {
          color: #000000;
        }
      }

      &.text {
        text-orientation: mixed;
        writing-mode: vertical-rl;
      }

      &.column {
        flex-direction: column;
      }

      &.red {
        color: $white;
        border-color: $primary-color;
        background-color: $primary-color;
      }
    }
  }

  &-card {
    background-color: white;
    border-radius: 16px;
    border: 1px solid #D9D9D9;
    display: flex;
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    align-self: stretch;
    margin-bottom: 35px;

    &-number {
      color: $primary-color;
      font-size: 32px;
      font-weight: 700;
      line-height: 20px; /* 62.5% */
      letter-spacing: 0.1px;
      margin-right: 10px;
    }

    &-question {
      color: #000;
      font-size: 15px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px; /* 133.333% */
      letter-spacing: 0.1px;
      flex-grow: 1;
    }

    &-arrow {
      transition: all 200ms;

      &.expanded {
        transform: rotate(180deg);
      }

      input {
        display: none;
      }
    }

    &-body {
      display: flex;
      width: 100%;
      transition: height 200ms;
      // height: 0;

      &.expanded {
        padding-top: 20px;
        height: initial;

        .page-card-response {
          resize: block !important;
          overflow: auto !important;

        }
      }


    }

    &-response {
      resize: none;
      overflow: hidden;
      color: rgba(0, 0, 0, 0.91);
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 153.846% */
      width: 100%;
      border: none;
    }
  }


  &-lbl {
    color: #000;
    font-size: 15px;
    font-weight: 700;
    line-height: 20px;
    display: block;
    margin-bottom: 23px;
  }

  &-drop {
    border-radius: 12px;
    padding: 14px;
    border: 1px solid #D9D9D9;
    cursor: pointer;
    margin: 0 0 15px;
    justify-content: space-between;

    display: flex;
    align-items: center;
    gap: 15px;

    span {
      color: $primary-color;
      font-size: 15px;
      font-weight: 600;
      line-height: 16px; /* 106.667% */
      letter-spacing: 0.1px;
    }
  }

  &-project {
    &-card {
      width: 100%;
      border-radius: var(--Corner-Medium, 12px);
      padding: 12px 28px;
      min-height: 61px;
      background: #D9D9D9;
      display: flex;
      align-items: center;
      gap: 37px;

      &-number {
        color: #000;
        font-size: 48px;
        font-weight: 700;
      }

      &-name {
        color: #000;
        font-size: 20px;
        font-weight: 700;
      }
    }
  }

  &-token {
    font-size: 15px;
    font-weight: 400;
    line-height: 1.3; /* 133.333% */
    color: black;
    letter-spacing: 0.1px;

    span {
      font-size: 32px;
      font-weight: 700;
    }
  }

  &-divider {
    width: 100%;
    height: 1px;
    margin-top: 30px;
    margin-bottom: 30px;
    background: #B1AEAE;
  }

}

.custom-play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  z-index: 10;
  width: 100px;
  height: 100px;
  background-color: $primary-color;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-play-button.hidden {
  transition: opacity 1s ease-in-out;
  opacity: 0;
}

// MODAL
#modalCustom,
#modalCustomTimer,
#modalCustomStrait,
#modalCustomStrait0,
#modalCustomStrait1,
#modalCustomStrait2,
#modalCustomStrait3,
#modalCustomStrait4,
#modalCustomTool {
  padding: 30px;
  border-radius: 38px;
  background-color: #D9D9D9;
  border: 2px solid $primary-color;
}

#modalCustomTool {
  background-color: $white;
}

.text-weight-700 {
  font-weight: 700 !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.dialog {
  &-header {
    gap: 20px;
    display: flex;
    min-height: 70px;
    align-items: center;
    border-radius: 20px;

    &.tech {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .dialog-header-title {
        color: #F00;
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        margin: 0;
      }

      .dialog-header-sub {
        color: #000;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        margin: 0;
      }
    }

    &.custom {
      max-height: 50px;
    }

    &.bg-white {
      background: #fff;
    }

    &-title {
      color: #000;
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
    }

    &-number {
      min-height: 70px;
      min-width: 100px;
      border-radius: 16px;
      background: #F00;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 40px;
      color: #fff;
      font-weight: 600;
    }
  }

  &-body {
    color: #000;
    max-width: 100%;
    font-size: 16px;
    font-weight: 400;
    margin: 60px auto 30px;
    line-height: 22px;

    &.middle {
      max-width: 70%;

      &.tech {
        max-width: 100%;
        margin: 0 0 35px;
      }
    }
  }

  &-footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
}

.text-center {
  text-align: center;
}

.copy-message {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: $primary-color;
  color: $white;
  padding: 10px 20px;
  border-radius: 5px;
  opacity: 0;
  font-size: 14px;
  animation: fadeInOut 2s forwards;

  @keyframes fadeInOut {
    0% {
      opacity: 0;
      transform: translateX(-50%) translateY(20px);
    }
    10% {
      opacity: 1;
      transform: translateX(-50%) translateY(0);
    }
    90% {
      opacity: 1;
      transform: translateX(-50%) translateY(0);
    }
    100% {
      opacity: 0;
      transform: translateX(-50%) translateY(20px);
    }
  }
}

.mat-dialog-container{
  max-height: 96vh !important;
}
